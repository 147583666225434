import React, { useState } from 'react';
import { useStateValue } from '../../StateProvider';
import SidebarLeft from '../Global/SidebarLeft';
import TopBar from '../Global/TopBar';
import Billing from './RegisteredSchoolDetails/billing/Billing';
import './SchoolManagement.css';

function SchoolManagementBilling() {

  return (
    <div className='home'>
      {/* <Header head={"School Management"} /> */}
      <TopBar/>
      <SidebarLeft/>
      {/* <Sidebar /> */}
      <div className="home_body" >
       <Billing/>
      </div>
    </div>
  )
}

export default SchoolManagementBilling;