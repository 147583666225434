import React, { useState, useEffect } from 'react';
import './Login.css';
import { useNavigate } from "react-router-dom";
import db, { auth } from '../../firebase';
import firebase from 'firebase';
import CircularProgress from '@mui/material/CircularProgress';

function Signup() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('');

  let navigate = useNavigate();
  // const [name, setName] = useState();

  var today = new Date();
  var datetime = today.toLocaleString();

  const create_new_account = () => {
    if (email && password) {
      setLoading(true)
      db.collection("AbleToMakeAccount").where("email", "==", email).get().then((querySnapshot) => {
        if (querySnapshot.empty === true) {
          setLoading(false);
          alert("You are not registered user. ")
        } else {
          querySnapshot.forEach((doc) => {
            // if()
            if (!doc?.data()?.schoolId) {
              // school@gmail.com
              setLoading(false)
              alert("This website only for schools!!");

            } else if (doc?.data()?.schoolId) {

              auth.createUserWithEmailAndPassword(email, password)
                .then((auth) => {
                  if (auth) {
                    db.collection("Registered User")
                      .doc(auth.user?.uid)
                      .set({
                        name: doc.data().name,
                        email: doc.data().email,
                        number: doc.data().number,
                        // password:password,
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        date: datetime,
                        value: "School Handler",
                        schoolName: doc?.data()?.schoolName,
                        schoolId: doc?.data()?.schoolId,
                      });
                    db.collection("Main-Section").doc("qQubJR2thuuMNeD2vjPK").update({
                      RegisteredCounsellor: firebase.firestore.FieldValue.increment(1),
                    })
                    setLoading(false)
                    navigate('/')
                  }
                })
                .catch((error) => {
                  setLoading(false)
                  alert(error.message)
                });
            }
          });
        }
      })
        .catch((error) => {
          alert("You may be not registered")
          setLoading(false)
        });
    } else {
      alert("Fill all the field");
      setLoading(false)
    }
  }

  return (
    <>
      {
        loading &&
        <div className="loadingBackground">
          <CircularProgress />
        </div>
      }
      <div className='Signup'>
        <div className="signup_body">
          <div className="signup_bodyDiv1">
            <div className="signupImagesDiv">
              <img src="/Images/key.png" alt="key image" />
              <img src="/Images/users.jpg" alt="book image" />
            </div>
            {/* <input type="text" placeholder='Name' onChange={e => setName(e.target.value)} className='signUp_input' /> */}
            <input type="text" placeholder='Given Email' onChange={e => setEmail(e.target.value)} className='signUp_input' />
            <input type="text" placeholder='New Password' onChange={e => setPassword(e.target.value)} className='signUp_input' />
            <div className="checkdiv">
              <input type="checkbox" />
              <span>Remember me</span>
            </div>
            <button onClick={create_new_account}>Signup</button>
            {/* <div className="forgot">
            forgot password
          </div> */}
          </div>
          <div className="signup_bodyDiv2">
            <img className='signup_bodyDiv2_img1' src="/Images/background.png" alt="background image" />
            <div className="signup_bodyDiv2div">
              <img className='signup_bodyDiv2_img2' src="/Images/Eduminattilogo.png" alt="" />
              <div className='signup2txt1'>Welcome!</div>
              <div className='signup2txt2'>
                <span onClick={() => navigate('/login')}>Login </span>
                {' '} if you are a first time user</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Signup;