import React, { useEffect, useState } from 'react'
import SchoolChart from '../SchoolChart';
import SchoolReportsField from './SchoolReportsField'
import SchoolReportsHeader from './SchoolReportsHeader'
import "../EachRegisteredSchoolHeader.css"
import { useStateValue } from '../../../../StateProvider';
function SchoolReport() {
  const [{ leads }, dispatch] = useStateValue();
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentleads, setCurrentleads] = useState([]);
  const [currentleads2, setCurrentleads2] = useState([]);

  const [currentVisits, setCurrentVisits] = useState([]);



  const increaseIndex = () => {
    if (currentIndex + 5 < currentleads.length) {
      setCurrentIndex(currentIndex + 5);

    } else {
      // console.log("nothing");
    }
  }

  const decreaseIndex = () => {
    if (currentIndex - 5 >= 0) {
      setCurrentIndex(currentIndex - 5);
    } else {
      // console.log("nothing");
    }
  }

  useEffect(() => {
    // console.log(leads)
    setCurrentleads(null)
    setCurrentleads2([]);
    if (leads) {
      setCurrentleads2(leads);
      leads.filter(n => {
        // console.log("nnn",n,leads)
        // AdmissionDate
        if (n?.data?.AdmissionDate) {
          const nn = currentleads;
          nn.push(n)
          setCurrentleads(nn)
        }
      })
    }
  }, [leads])

  useEffect(() => {
    // console.log(leads)
    setCurrentVisits(null)
    if (leads) {
      leads.filter(n => {
        // console.log("nnn",n,leads)
        // AdmissionDate
        if (n?.data?.visitedDate) {
          const nn = currentVisits;
          nn.push(n)
          setCurrentVisits(nn)
        }
      })
    }
  }, [leads])


  return (
    <div className='SchoolReports'>
      {/* <div className="SchoolReports_top">
                <div className="SchoolReports_top_fir">
                    Tons Bridge School, Dehradun
                </div>
                <div className="SchoolReports_top_sec">
                    <span>Last 7 Days</span>
                    <input type="date" />
                </div>
            </div> */}
      <div className=".SchoolReports_body" style={{ marginTop: "12px" }}>
        <div className="SchoolReports_body_1">
          <div className="SchoolReports_topfour">
            <div className="SchoolReports_box1 SchoolReports_box">
              <div className="SchoolReports_num">
                {leads ? leads?.length : '0'}
               
              </div>
              <div className="SchoolReports_text">
                Total leads
              </div>
            </div>
            <div className="SchoolReports_box2 SchoolReports_box">
              <div className="SchoolReports_num">
                {currentleads ? currentleads?.length : '0'}
              </div>
              <div className="SchoolReports_text">
                Admissions Done
              </div>
            </div>
            <div className="SchoolReports_box3 SchoolReports_box">
              <div className="SchoolReports_num">
                {(leads && leads?.length) - (currentleads?.length ? currentleads?.length : 0)}
               
              </div>
              <div className="SchoolReports_text">
                Appointments Scheduled
              </div>
            </div>
            <div className="SchoolReports_box4 SchoolReports_box">
              <div className="SchoolReports_num">
                {currentVisits ? currentVisits.length:0}
              </div>
              <div className="SchoolReports_text">
                School Visits
              </div>
            </div>
          </div>
          <div className="SchoolReports_body_chart">
            <SchoolChart currentVisits={currentVisits}  leads={leads} currentleads={currentleads}/>
          </div>
        </div>
        <div className="SchoolReports_body_2">
          <div className="SchoolReports_body_2Header">
            <h2> Recent Leads</h2>
            
          </div>
          <div className="SchoolReports_body_2body">
            <SchoolReportsHeader />
            {currentleads2 && currentleads2.length > 0 ? currentleads2.slice(currentIndex, currentIndex + 5).map((data, k) =>
              <SchoolReportsField data={data} k={k} c={currentIndex} />
            )
              :
              <div style={{ height: "100%", width: "100)%", alignItems: "center", justifyContent: "center", display: 'flex' }}>
                No data
              </div>
            }
            {
              currentleads && currentleads.length > 0 &&
              <div className="home_last_row" style={{ position: "absolute", bottom: "6px", margin: 0 }}>
                <div className="share_first">
                  {/* <img src="/Images/del.png" alt="" />
            <img src="/Images/share.png" alt="" /> */}
                </div>
                <div className="paginate_last">
                  <div>{currentIndex}-{currentIndex + 5 > currentleads.length ? currentleads.length : currentIndex + 5} of {currentleads.length}</div>
                  <img src="/Images/left.png" alt="" onClick={decreaseIndex} />
                  <img src="/Images/right.png" alt="" onClick={increaseIndex} />
                </div>
              </div>}
          </div>
        </div>
      </div>
    </div>



  )
}

export default SchoolReport