import React from 'react'
import styled from 'styled-components';
import './TopBar.css'

function TopBar({ value }) {
    return (
        <Container>
            <img className='Logo' src="/Images/school/Logo.svg" alt="" />
            <h6 >{value}</h6>
        </Container>
    )
}

export default TopBar;

const Container = styled.div`
/* position:absolute ; */
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-around; */
    width: calc(100% - 10px);
    min-width:1300px ;
    border-bottom-right-radius:45px ;
    height:60px ;
    /* min-height:500px; */
    background-color:#247BB2 ;
    /* padding:12px ; */
    align-items:center;
    h6{
        margin: 0;
        padding: 0;
        font-size:35px ;
        color: white;
        margin-left:50px;
        display: flex;
        justify-content: center;
        font-weight:600 ;
        letter-spacing:0.5px ;
    }
`