export const initialState = {
  user: null,
  signInAs: null,
  boards: ["CBSE", "ICSE", "IGCSE", "IB", "CIE"],
  categories: ["Day", "Day Boarding", "Full Boarding", "Girls", "Boys", "Coed"],
  budget: ["1-3", "3-5", "5-7", "7-9", "9-11", "11+"],
  standards: ["Nursury", "LKG", "UKG", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th"],
  status:[{label:"New",value:"#BE44D1"},{label:"Cold",value:"#466FFF"},{label:"Hot",value:"#FC7272"},{label:"Warm",value:"#F49741"},{label:"Success",value:"#5FB51B"}],
  leads:null,
  schoolDetails:null,
  // pathname:'',
  // // value: 'one', label: 'One'
  // selectedField: [],
  // popUp_filter:false,
  // cursor_list: false,
  // cursor_drop: false,
  // filterCat:'',
  // filterBoard:'',
  // filterBudget:'',
  // filterSex:'',
  // filterStandard:'',
  // registeredSch:null,
  // currentTab:'All',
  // currentSchool:null,
  // searchby:"Name",
  // mainData:null,
  // registeredUser:null,
  // showRecentChanges:false,
};

export const actionTypes = {
  SET_USER: "SET_USER",
  // SET_MAIN_DATA: "SET_MAIN_DATA",
  // SET_PATHNAME: "SET_PATHNAME",
  SIGN_IN_AS: "SIGN_IN_AS",
  SET_ALL_LEADS: "SET_ALL_LEADS",
  SET_SCHOOL_DETAILS:"SET_SCHOOL_DETAILS"
  // SET_CURSOR_LIST: "SET_CURSOR_LIST",
  // SET_CURSOR_DROP: "SET_CURSOR_DROP",
  // SET_SELECTED_LIST: "SET_SELECTED_LIST",
  // SET_FILTER_POPUP:"SET_FILTER_POPUP",
  // SET_FILTER_CAT:"SET_FILTER_CAT",
  // SET_FILTER_BOARD:"SET_FILTER_BOARD",
  // SET_FILTER_BUDGET:"SET_FILTER_BUDGET",
  // SET_FILTER_SEX:"SET_FILTER_SEX",
  // SET_FILTER_STANDARD:"SET_FILTER_STANDARD",
  // SET_REGISTERED_SCHOOL:"SET_REGISTERED_SCHOOL",
  // SET_CURRENT_TAB:"SET_CURRENT_TAB",
  // SET_CURRENT_SCHOOL:"SET_CURRENT_SCHOOL",
  // SET_SEARCHBY:"SET_SEARCHBY",
  // SET_REGISTERED_USER:"SET_REGISTERED_USER",
};

const reducer = (state, action) => {

  switch (action.type) {
    case actionTypes.SET_ALL_LEADS:
      return {
        ...state,
        leads: action.leads,
      };
    // case actionTypes.SET_MAIN_DATA:
    //   return {
    //     ...state,
    //     mainData: action.mainData,
    //   };
    // case actionTypes.SET_SEARCHBY:
    //   return {
    //     ...state,
    //     searchby: action.searchby,
    //   };
    // case actionTypes.SET_CURRENT_SCHOOL:
    //   return {
    //     ...state,
    //     currentSchool: action.currentSchool,
    //   };
    // case actionTypes.SET_CURRENT_TAB:
    //   return {
    //     ...state,
    //     currentTab: action.currentTab,
    //   };
    // case actionTypes.SET_PATHNAME:
    //   return {
    //     ...state,
    //     pathname: action.pathname,
    //   };
    // case actionTypes.SET_REGISTERED_SCHOOL:
    //   return {
    //     ...state,
    //     registeredSch: action.registeredSch,
    //   };
    // case actionTypes.SET_FILTER_STANDARD:
    //   return {
    //     ...state,
    //     filterStandard: action.filterStandard,
    //   };
    // case actionTypes.SET_FILTER_SEX:
    //   return {
    //     ...state,
    //     filterSex: action.filterSex,
    //   };
    // case actionTypes.SET_FILTER_BUDGET:
    //   return {
    //     ...state,
    //     filterBudget: action.filterBudget,
    //   };
    // case actionTypes.SET_FILTER_BOARD:
    //   return {
    //     ...state,
    //     filterBoard: action.filterBoard,
    //   };
    // case actionTypes.SET_FILTER_CAT:
    //   return {
    //     ...state,
    //     filterCat: action.filterCat,
    //   };
    // case actionTypes.SET_FILTER_POPUP:
    //   return {
    //     ...state,
    //     popUp_filter: action.popUp_filter,
    //   };
    // case actionTypes.SET_SELECTED_LIST:
    //   return {
    //     ...state,
    //     selectedField: action.selectedField,
    //   };
    // case actionTypes.SET_CURSOR_DROP:
    //   return {
    //     ...state,
    //     cursor_drop: action.cursor_drop,
    //   };
    // case actionTypes.SET_CURSOR_LIST:
    //   return {
    //     ...state,
    //     cursor_list: action.cursor_list,
    //   };
      case actionTypes.SIGN_IN_AS:
        return {
          ...state,
          signInAs: action.signInAs,
        };
      case actionTypes.SET_USER:
        return {
          ...state,
          user: action.user,
        }
        case actionTypes.SET_SCHOOL_DETAILS:
          return {
            ...state,
            schoolDetails: action.schoolDetails,
          }
    default:
      return state;
  }
};

export default reducer;
