import React, { useState } from 'react';
import { useStateValue } from '../../StateProvider';
import SidebarLeft from '../Global/SidebarLeft';
import TopBar from '../Global/TopBar';
import SchoolReport from './RegisteredSchoolDetails/report/SchoolReport';
import './SchoolManagement.css';

function SchoolManagement() {

  return (
    <div className='home'>
      {/* <Header head={"School Management"} /> */}
      <TopBar value={"Home"}/>
      <SidebarLeft/>
      {/* <Sidebar /> */}
      <div className="home_body" >
       <SchoolReport/>
      </div>
    </div>
  )
}

export default SchoolManagement;