import React, {useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate,useLocation } from "react-router-dom";
import firebase from "firebase";
import "./SidebarLeft.css";

function SidebarLeft() {
  const [popup, setPopup] = useState(false);
  const [active, setActive] = useState(0);

  const navigate = useNavigate();

 
        const location = useLocation();
        const path= location.pathname;

  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.reload();
        navigate("/signIn");
      });
  };


  useEffect(()=>{
    if(path?.includes("Home")){
setActive(1);
    }else if(path?.includes("Admissions")){
        setActive(2);

    }
},[])


  return (
    <>
      <Container>
        <div
          className="Top"
          onClick={() => {
            navigate("/Home");
          }}
        >
          <img className={active===1 ? "active" : "" } src="/Images/school/Home.svg" alt="" />
        </div>

        {/* <div onClick={() => navigate(`/profile`)}>
                    <img src="/Images/school/User_icon.png" alt="" />
                    <span>Profile</span>
                </div> */}
        {/* <div onClick={() => navigate(`/school`)}>
                    <img src="/Images/school/school_disabled.png" alt="" />
                    <span>School</span>
                </div> */}
        <div onClick={() => navigate(`/`)}>
          <img  className={active===0 ? "active" : "" } src="/Images/school/Leads.svg" alt="" />
        </div>

        <div
          onClick={() => {
            navigate("/Admissions");
          }}
        >
          <img  className={active===2 ? "active" : "" } src="/Images/school/Admission.svg" alt="" />
        </div>

        {/* <div onClick={() => navigate(`/slots`)}>
                    <img src="/Images/school/slot.png" alt="" />
                    <span>Slots</span>
                </div> */}
        {/* <div onClick={() => { navigate('/stats') }}>
                    <img src="/Images/school/stat.png" alt="" />
                    <span>Home</span>
                </div> */}
        {/* <div onClick={() => navigate(`/billing`)}>
                    <img src="/Images/school/Billing_icon.png" alt="" />
                    <span>Billing</span>
                </div> */}
        {/* <div onClick={() => { }}>
                    <img src="/Images/school/Help_icon.png" alt="" />
                    <span>Help</span>
                </div> */}
        <div>
          <img
            className="Setting_icons"
            src="/Images/school/icons.svg"
            alt=""
          />
        </div>

        <div>
          <img
            className="Logout"
            src="/Images/school/Logout.svg"
            alt=""
            onClick={() => {
              setPopup(true);
            }}
          />
        </div>
      </Container>
      {popup && (
        <Container2>
          <div>
            <p className="pop_info_cross">
              <img
                onClick={() => setPopup(false)}
                src="/Images/cross.png"
                alt=""
              />
            </p>
            <h4>Are you sure? </h4>

            <span>
              {/* <div> */}
              <button className="buttonForUpdateForDec" onClick={() => {}}>
                No
              </button>
              <button className="buttonForUpdate" onClick={logOut}>
                Yes
              </button>
              {/* </div> */}
            </span>
          </div>
        </Container2>
      )}
    </>
  );
}

export default SidebarLeft;

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 60px;
  height: calc(100% - 24px - 60px);
  min-height: 500px;
  background-color: #247bb2;
  padding: 12px;
  border-bottom-right-radius: 45px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 12%;
  }
  img {
    height: 60px;
    width: 60px;
  }
  span {
    font-size: 13px;
    font-weight: 500;
    color: lightgray;
  }
  /* align-items: ; */
`;
const Container2 = styled.div`
  z-index: 1000;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0%;
  background-color: #00000073;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    padding: 0;
    margin: 6px 0;
    display: flex;
    width: 90%;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    height: 170px;
    width: 250px;
    border-radius: 6px;
    background-color: #e9e7e7;
  }
  textarea {
    resize: none;
    height: 100px;
    width: 90%;
  }
  button {
    margin: 12px;
    width: fit-content;
    padding: 8px 16px;
    outline: none;
    border: none;
    color: #ffffff;
    font-weight: 600;
    background-color: #138ffb;
  }
  span {
    display: flex;
    width: 95%;
    justify-content: center;
  }
  .buttonForUpdate {
    width: 40%;
    margin: 4%;
    border: none;
    color: white;
    padding: 9px 9px;
    background-color: #1878d8;
    border-radius: 6px;
    font-weight: 600;
  }
  .buttonForUpdateForDec {
    width: 40%;
    margin: 4%;
    border: none;
    color: white;
    padding: 9px 9px;
    background-color: #ef5334;
    border-radius: 6px;
    font-weight: 600;
  }
`;
