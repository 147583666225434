import React, { useState } from 'react';
import { useStateValue } from '../../StateProvider';
import SidebarLeft from '../Global/SidebarLeft';
import TopBar from '../Global/TopBar';


function Admissions() {

  return (
    <div className='home'>
     
      <TopBar value={"Admissions"}/>
      <SidebarLeft/>
     
      <div className="home_body" >
       <p> This Feature Will Be Coming Soon</p>
      </div>
    </div>
  )
}

export default Admissions;