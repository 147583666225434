import React, { useEffect, useState } from "react";
import db from "../../firebase";
import { actionTypes } from "../../reducer";
import { useStateValue } from "../../StateProvider";
import SidebarLeft from "../Global/SidebarLeft";
import TopBar from "../Global/TopBar";
import "./Home.css";
import Lead_Field from "./Lead_Field";
import Lead_Header from "./Lead_Header";

function Home() {
  const [{ signInAs, leads }, dispatch] = useStateValue();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [active, setActive] = useState(-1);

  const [state, setState] = useState("Enquiry");

  const increaseIndex = () => {
    if (currentIndex + 8 < leads.length) {
      setCurrentIndex(currentIndex + 8);
    } else {
      console.log("nothing");
    }
  };

  const decreaseIndex = () => {
    if (currentIndex - 8 >= 0) {
      setCurrentIndex(currentIndex - 8);
    } else {
      console.log("nothing");
    }
  }
;
useEffect(()=>{
  setActive(-1);
  if(!leads?.length) return;
for (let i = 0; i < leads.length; i++) {
  if(!leads[i]?.data?.schoolRemark || leads[i]?.data?.schoolRemark==""){
    console.log("innn");
    console.log(i);
    console.log(currentIndex * 8);

    setActive(i);
    break;
  }
}
},[leads])
  return (
   
    <div>
      <TopBar value={"Enquiry"} />
      <SidebarLeft />
      <h6 className="abc" >Note - Remark Field is Mandatory</h6>
      <div className="home_body">
        
        <div className="home_leads_box">
          <div className="lead_box_firstDiv">
            
          </div>
       
          <div className="lead_box_Sec">
      
            <Lead_Header />
            {leads &&
              leads.length > 0 &&
              leads
                .slice(currentIndex, currentIndex + 8)
                .map((data,index) => <Lead_Field key={currentIndex + index} data={data} setActive={setActive} active={currentIndex + index <= active}/>)}
          </div>
        
        <div className="home_last_row">
          <div className="share_first">
            <img src="/Images/del.png" alt="" />
            <img src="/Images/share.png" alt="" />
          </div>
          <div className="paginate_last">
            <div>
              {currentIndex}-
              {currentIndex + 8 > leads?.length
                ? leads?.length
                : currentIndex + 8}{" "}
              of {leads?.length}
            </div>
            <img src="/Images/left.png" alt="" onClick={decreaseIndex} />
            <img src="/Images/right.png" alt="" onClick={increaseIndex} />
          </div>
         
          </div>
       
          </div>
         
          {/* <marquee width="60%" direction="left" height="20px">
          Note - The Website Under Maintenance.
</marquee> */}

      </div >
   
    </div>
   
  );
}

export default Home;
