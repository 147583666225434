import React from 'react';
import './Lead_Header.css'



function Lead_Header() {
  
  return (
    <div className='Lead_Header'>
      <div className="Date" >
        Date
      </div>
    

      <div className="NameHeader">
        Name
      </div>
      <div className="ContactHeader">
        Contact
      </div>
      <div className="EmailHeader">
        Email
      </div>
      <div className="gender">
        Gender
      </div>
      <div className="gender">
        Standard
      </div>
      <div className="Board1">
      Proceed 
      </div>

      {/* <div className="Email2">
      Appointment 
      </div> */}

      <div className="Email2">
      Remark 
      </div>

      {/* <div className="Approved">
         Approved
      </div> */}

      <div className="Registration">
        Registration
      </div>  

      <div className="Admission">
        Admission
      </div>
      
      {/* <div className="statusNew">
        Status
      </div> */}
      {/* <div className="morevert">
      </div> */}
    </div>
  )
}

export default Lead_Header