import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import { actionTypes } from './reducer';
import db, { auth } from './firebase';
import Home from "./Components/Home/Home";
import Signup from "./Components/Login/Signup";
import Login from "./Components/Login/Login";
import SchoolManagement from "./Components/SchoolManegement/SchoolManagement";
import SchoolManagementBilling from "./Components/SchoolManegement/SchoolManagementBilling";
import Slots from "./Components/Slots/Slots";
import SchoolInfo from "./Components/SchoolManegement/RegisteredSchoolDetails/Info/SchoolInfo";
import CircularProgress from '@mui/material/CircularProgress';
import Admissions from "./Components/Admissions/Admissions";
import './App.css';
import SubscribePopUp from "./Components/Home/SubscribePopUp";

function App() {

  const [{ signInAs, user }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (signInAs)
      db.collection("Registered Schools").doc(signInAs?.schoolId).collection("All Visits")
        .orderBy("timestamp", "desc")
        // .limit(30)
        .onSnapshot((snapshot) =>
          dispatch({
            type: actionTypes.SET_ALL_LEADS,
            leads: snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            })),
          })
        );
  }, [signInAs?.schoolId]);



  // useEffect(() => {
  //   if (signInAs)
  //     db.collection("Registered Schools").doc(signInAs?.schoolId)
      
  //       .onSnapshot((snapshot) =>
  //         dispatch({
  //         type: actionTypes.SET_SCHOOL_DETAILS,
  //           schoolDetails: snapshot.data(),
  //         })
  //           );
  // }, [signInAs?.schoolId]);

  useEffect(() => {
    if (signInAs) {
      // console.log(signInAs);
      // {console.log("user?.uid",signInAs?.schoolId)}
      db.collection("Registered Schools").doc(signInAs?.schoolId).onSnapshot((snapshot) =>
      {
      //   console.log("snapshot",snapshot)
      // console.log(signInAs?.schoolId);
      // console.log("snapshot Data",snapshot.data())
        dispatch({
          type: actionTypes.SET_SCHOOL_DETAILS,
          schoolDetails: snapshot.data(),
       
        })
       
      }
      )
    }
  }, [signInAs]);
 

  useEffect(() => {
    // will only run once when the app component loads...
    auth.onAuthStateChanged((auth) => {
      if (auth) {
        dispatch({
          type: actionTypes.SET_USER,
          user: auth,
        });
      } else {

      }
    });
  }, []);

  useEffect(() => {
    if (user?.uid) {
      setLoading(true);
      // console.log("user?.uid");
      // console.log(user.uid);
      db.collection('Registered User').doc(user?.uid).onSnapshot((snapshot) =>
      {
        // console.log("user?.uid", snapshot.data());
        dispatch({
          type: actionTypes.SIGN_IN_AS,
          signInAs: snapshot.data(),
        })
        setLoading(false)
      }
      )
    }
  }, [user?.uid]);

  useEffect(() => {
    db.collection("Registered User").orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        dispatch({
          type: actionTypes.SET_REGISTERED_USER,
          registeredUser: snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          })),
        })
      }
      );
  }, []);


  return (
    <>
    {
      loading && 
      <div className="loadingBackground">
        <CircularProgress/>
      </div>
    }



    <Router>
      <Routes>
        <>
          {signInAs?.value === "School Handler" ?
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/Home" element={<SchoolManagement />} />
              <Route path="/Admissions" element={<Admissions />} />
              <Route path="/slots" element={<Slots />} />
              <Route path="/school" element={<SchoolInfo />} />
              <Route path="/billing" element={<SchoolManagementBilling />} />
              <Route path="*" element={<Home />} />
             
            </>


            :
            <>
              <Route path="/signup" element={<Signup />} />
              <Route path="*" element={<Login />} />
            </>
          }
        </>
      </Routes>
    </Router>
    { <SubscribePopUp/>}
            </>
  );
}

export default App;