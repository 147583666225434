import React, { useState } from 'react'
import { useEffect } from 'react';
import db from '../../../../firebase';
import { useStateValue } from '../../../../StateProvider';
import SidebarLeft from '../../../Global/SidebarLeft';
import TopBar from '../../../Global/TopBar';
import './SchoolInfo.css';
import firebase from 'firebase';
import styled from 'styled-components';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';

function SchoolInfo() {

  const [{ signInAs }, dispatch] = useStateValue();

  const [popup, setPopup] = useState(false);
  const [data, setData] = useState(false);
  const [showPopForAdd, setShowPopForAdd] = useState(false);
  const [about, setAbout] = useState('');
  const [address, setAddress] = useState('');
  const [board, setBoard] = useState('');
  const [category, setCategory] = useState('');
  const [esd, setEsd] = useState('');
  const [grade, setGrade] = useState('');
  const [fees, setFees] = useState('');
  const [images, setImages] = useState([]);
  const [images1, setImages1] = useState('');
  const [images2, setImages2] = useState('');
  const [images3, setImages3] = useState('');
  const [images4, setImages4] = useState('');
  const [name, setName] = useState('');
  const [facilities, setFacilities] = useState([]);

  var today = new Date();
  var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  useEffect(() => {
    if (signInAs)
      db.collection("Registered Schools").doc(signInAs?.schoolId).onSnapshot((snapshot) => (
        setData(snapshot.data())
      ))
  }, [signInAs?.schoolId]);


  const updateSchool = () => {
    db.collection('School Management').doc("School Management").collection("Recent Changes").add({
      id: signInAs?.schoolId,
      status: "Pending",
      date: date,
      time: time,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      About: about,
      Address: address,
      Board: board,
      Category: category,
      Esd: esd,
      Grade: grade,
      Fees: fees,
    }).then(() => {
      db.collection("Categories").doc('categories').update({
        Schools: firebase.firestore.FieldValue.arrayUnion(
          name
        ),
      })
      alert("School update successfully!")
    })
      .catch(() => {
        alert("Something went wrong!")
      })
  }

  return (
    <Container>
      {
        showPopForAdd &&
        <div className="showPopForAddDiv">
          <div className="showPopForAddDivIn">
            <h6 style={{marginBottom:"4px",marginTop:"10px"}}>Update School</h6>
            <input type="text" placeholder='Name' onChange={e => setName(e.target.value)} />
            <textarea type="text" placeholder='Address' onChange={e => setAddress(e.target.value)} />
            <textarea type="text" placeholder='About' onChange={e => setAbout(e.target.value)} />
            {/* <textarea type="text" placeholder='setImages1' onChange={e => setImages(e.target.value)} /> */}
            {/* <input type="text" placeholder='Paste link of facilities separated by "," ' onChange={e => setFacilities(e.target.value)} /> */}
            <input type="text" placeholder='Fees' onChange={e => setFees(e.target.value)} />
            <input type="text" placeholder='Grade' onChange={e => setGrade(e.target.value)} />
            <input type="text" placeholder='Board' onChange={e => setBoard(e.target.value)} />
            <input type="text" placeholder='Category' onChange={e => setCategory(e.target.value)} />
            <input type="text" placeholder='Esd' onChange={e => setEsd(e.target.value)} />
            <div className='ClassForButtons'>
              <button className='buttonForUpdateForDec' onClick={() => {
                setShowPopForAdd(false)
              }}>Cancel</button>
              <button className='buttonForUpdate' onClick={updateSchool}>Update</button>
            </div>
            {/* <Dropdown options={["", "Female"]} onChange={e => { setBoard(e.value) }}  placeholder="Board" controlClassName='dropDown' /> */}
          </div>
        </div>
      }
      <TopBar value={"School"} />
      <SidebarLeft />
      <div className="home_body">

        <div className="SchoolInfo">
          <div className="SchoolInfoTop">
            <div className="SchoolInfofir">
              <div className="SchoolInfofirdiv">
                <div className="SchoolInfofirName">
                  School Name:
                </div>
                <div className="SchoolInfofirtxt" style={{display:"flex"}}>
                  {data?.name}
                  <div style={{paddingLeft:"9px"}} onClick={()=>{
                    setShowPopForAdd(true);
                  }}>
                    <ModeEditRoundedIcon fontSize='small'/>
                  </div>
                </div>
              </div>
              <div className="SchoolInfofirdiv">
                <div className="SchoolInfofirName">
                  Address:
                </div>
                <div className="SchoolInfofirtxt">
                  {data?.Address}
                </div>
              </div>
              <div className="SchoolInfofirdiv">
                <div className="SchoolInfofirName">
                  Board:
                </div>
                <div className="SchoolInfofirtxt">
                  {data?.Board}
                </div>
              </div>
              <div className="SchoolInfofirdiv">
                <div className="SchoolInfofirName">
                  Type:
                </div>
                <div className="SchoolInfofirtxt">
                  {data?.Category}
                </div>
              </div>
              <div className="SchoolInfofirdiv">
                <div className="SchoolInfofirName">
                  Established:
                </div>
                <div className="SchoolInfofirtxt">
                  {data?.Esd}
                </div>
              </div>
              <div className="SchoolInfofirdiv">
                <div className="SchoolInfofirName">
                  Class:
                </div>
                <div className="SchoolInfofirtxt">
                  {data?.Grade}
                </div>
              </div>
              <div className="SchoolInfofirdiv">
                <div className="SchoolInfofirName">
                  Fee:
                </div>
                <div className="SchoolInfofirtxt">
                  INR {data?.Fees} / year
                </div>
              </div>
            </div>

            <div className="SchoolInfosec">
              <h3>Facilities:-</h3>
              {data?.Facilities && data?.Facilities.length > 0 && data?.Facilities.split(",").map((fac) =>
                <li>{fac}</li>
              )}
            </div>
          </div>
          <div className="SchoolInfolast">
            <div className="SchoolInfolast_fir">
              <div className="SchoolInfofirName">
                About:
              </div>
              <p>
                {data?.About}
              </p>
            </div>
            <div className="SchoolInfolast_last">
              <div className="SchoolInfofirName">
                Gallery:
              </div>
              <div className="SchoolInfolast_last_imgfolder">
                {data?.Images && data?.Images.map((image) => (
                  <img src={image} alt="" />
                  // <img src="/Images/schoolimg.png" alt="" />
                ))}
                {/*<img src="/Images/schoolimg.png" alt="" />
              <img src="/Images/schoolimg.png" alt="" />
            <img src="/Images/schoolimg.png" alt="" /> */}
                <div className='Customize_txt' onClick={() => { setPopup(true) }} >
                  <img src="/Images/edit.png" alt="" />
                  <div className="Customize_img">
                    Customize
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            popup &&
            <div className='Popup_info'>
              <div className="pop_info">
                <div className="pop_info_cross">
                  <img onClick={() => setPopup(false)} src="/Images/cross.png" alt="" />
                </div>
                <div className="pop_info_img">
                  <img src="/Images/schoolimg.png" alt="" />
                  <img src="/Images/schoolimg.png" alt="" />
                  <img src="/Images/schoolimg.png" alt="" />
                  <div className='addImage_box'>
                    <img src="/Images/addcir.png" alt="" />
                    <span>Add Image</span>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </Container>
  )
}

export default SchoolInfo;

const Container = styled.div`
  .showPopForAddDiv{
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    position: fixed;
    background-color: #626262b8;
    height: 100%;
    width: 100vw;
    min-width: 1300px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .showPopForAddDivIn{
    width: 500px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .buttonForUpdate{
        width: 40%;
        margin: 4%;
        border: none;
        color: white;
        padding: 9px 9px;
        background-color: #1878d8;
        border-radius: 6px;
        font-weight: 600;
    }
    .buttonForUpdateForDec{
        width: 40%;
        margin: 4%;
        border: none;
        color: white;
        padding: 9px 9px;
        background-color: #ef5334;
        border-radius: 6px;
        font-weight: 600;
    }
    input{
      border: 1px solid lightgray;
      outline:none;
      width: 90%;
      margin: 4px 0;
      padding: 6px;
    }
    textarea{
      resize: none;
      border: 1px solid lightgray;
      outline:none;
      width: 90%;
      margin: 4px 0;
      padding: 6px;
      min-height: 100px;
    }
    .ClassForButtons{
      width: 80%;
    }
`






// function SchoolInfo  () {
//   return (
//     <div> 
//        <TopBar value={"School Details"} />
//        <SidebarLeft />
//       <div className="home_body"> This Feature Not Available</div>
//     </div>
//   )
// }

// export default SchoolInfo;