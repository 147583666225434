import React, { useState,useEffect } from "react";
import "./SubscribePopUp.css";
import { useStateValue } from '../../StateProvider';


export default function SubscribePopUp() {

const [{ schoolDetails }, dispatch] = useStateValue();

  const [modal, setModal] = useState(false);
  const [count, setCount] = useState();

//   console.log( "details",schoolDetails)
  console.log( "totalLeadsSeen",schoolDetails?.totalLeadsSeen)


  const toggleModal = () => {
    setModal(!modal);
  };

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  useEffect(() => {
    if (schoolDetails?.totalLeadsSeen >= 10 && schoolDetails?.subscription !=="Subscribed") setModal(true)
   
  },[schoolDetails?.totalLeadsSeen,schoolDetails?.subscription])


  return (
    <>
      {/* <button onClick={toggleModal} className="btn-modal">
        Open
      </button> */}

      {modal && (
        <div className="modal">
          <div className="overlay"></div>
          <div className="modal-content">
            <h2 style={{margin: "0"}}>Unlock Premium Now! </h2>
            <p style={{fontSize: "20px"}}>
            Oops! It looks like you've used up all your trial leads. To unlock unlimited leads from Eduminatti contact us at <span style={{color: "#247bb2", fontSize:"20px"}}>+91 7300598181</span> 
            </p>
            {/* <a href="tel: 7300598181">
            <button className="" >
              CALL
            </button></a> */}
            {/* <button className="close-modal" onClick={toggleModal}>
              CLOSE
            </button> */}
             
          </div>
          {/* <button className="close-modal" >
              CALL
            </button> */}
        </div>
      )}
  
    </>
  );
}