import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyBwk0NVtxf_aU5bCdUOmSzYlWSDOddmn74",
  authDomain: "dashboard-eduminatti.firebaseapp.com",
  projectId: "dashboard-eduminatti",
  storageBucket: "dashboard-eduminatti.appspot.com",
  messagingSenderId: "934253307854",
  appId: "1:934253307854:web:b3e41c9b5c2eb305b8ed9f",
  measurementId: "G-8G5JNJPPJ0"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();

// const storage = firebase.storage();
// const provider=new firebase.auth.GoogleAuthProvider();
export { auth};

export default db; 