import React, { useState, useEffect } from "react";
import { useStateValue } from "../../StateProvider";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { actionTypes } from "../../reducer";
import db from "../../firebase";
import styled from "styled-components";
// import Popup from 'reactjs-popup';
// import SendRoundedIcon from '@mui/icons-material/SendRounded';
// import IconButton from '@mui/material/IconButton';
import firebase from "firebase";
// import ResponseFromSchool from './ResponseFromSchool';
function Lead_Field({ data, name = "" ,active=false}) {
  const [
    {
      signInAs,
      boards,
      categories,
      budget,
      standards,
      selectedField,
      status,
      popforleadfield,
      registeredUser,
      schoolDetails,
    },
    dispatch,
  ] = useStateValue();
  

  // const [popUp_filter_field, setPopUp_filter_field] = useState(false)
  const [selectedLine, setSelectedLine] = useState(selectedField);
  const [inputChecked, setInputChecked] = useState(false);
  const [loading, setLoading] = useState(-1);
  // for pop side vala
  const [openmore, setOpenmore] = useState(false);
  const [showSendToPop, setShowSendToPop] = useState(false);
  const [responseFromSchool, setResponseFromSchool] = useState(false);
  const [currentSchoolName, setCurrentSchoolName] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(false);

  const arrClaimProceed = ["Proceed", "Don't Proceed"];
  const arrApproved = ["Accept"];

  const [Remark, setRemark] = useState("");
  const [registration,setRegistration] = useState("");
  const [admission,setAdmission] = useState("");

  let remarkInputTimer;
  let registrationTimer;
  let admissionTimer;


  console.log(signInAs);

  console.log(signInAs);
  const check__checkBox = (e, data) => {
    const checked = e.target.checked;
    setInputChecked(checked);
    if (checked) {
      selectedLine.push(data);
      const na = [...selectedLine];
      setSelectedLine(na);
      // console.log("ggcc", na)
      dispatch({
        type: actionTypes.SET_SELECTED_LIST,
        selectedField: selectedLine,
      });
    } else {
      const index = selectedLine.findIndex(checkAge);
      function checkAge(age) {
        console.log("ggage", age, data);
        return age.Email == data.Email;
      }
      if (index >= 0) {
        selectedLine.splice(index, 1);
        const na = [...selectedLine];
        setSelectedLine(na);
        dispatch({
          type: actionTypes.SET_SELECTED_LIST,
          selectedField: selectedLine,
        });
      } else {
        console.warn(`Cant remove!`);
      }
    }
  };

  const deleteField = () => {
    setOpenmore(false);
    db.collection("Leads")
      .doc(data?.id)
      .delete()
      .then(() => {
        setOpenmore(false);
        alert("Field Deleted Successfully");
      });
  };

  const claimProceed = (e) => {
    //  console.log("Registered",data)
    if (data?.id) {
      db.collection("Registered Schools")
        .doc(signInAs?.schoolId)
        .collection("All Visits")
        .doc(data?.id)
        .update({
          response: e.value,
        })
        .then(() => {
          db.collection("Leads")
            .doc(data?.id)
            .update({
              // sentSchool: (e.value == "Proceed") ? 'data?.data?.sentSchool + A' : 'data?.data?.sentSchool',
              response: e.value,
              proceedSchools:
                e.value == "Proceed"
                  ? firebase.firestore.FieldValue.arrayUnion(signInAs?.schoolId)
                  : firebase.firestore.FieldValue.arrayUnion(""),
              // color: "#00e673",
              color: e.value == "Proceed" ? "#33cc33" : "#ff1a1a",
            })
            .then(() => {
              db.collection("Registered Schools")
              .doc(signInAs?.schoolId)
              .update({
                totalLeadsSeen: schoolDetails?.totalLeadsSeen ? schoolDetails?.totalLeadsSeen+1 : 1,
              })
            });
        });
    }
  };

  const Approved = (e) => {
    if (data?.id) {
      db.collection("Registered Schools")
        .doc(signInAs?.schoolId)
        .collection("All Visits")
        .doc(data?.id)
        .update({
          Approved: e.value,
        })
        .then(() => {
          db.collection("Leads")
            .doc(data?.id)
            .update({
              Approved: e.value,
            })
            .then(() => {});
        });
    }
  };

  const schoolRemark = (e) => {
    if (data?.id) {
      db.collection("Registered Schools")
        .doc(signInAs?.schoolId)
        .collection("All Visits")
        .doc(data?.id)
        .update({
          schoolRemark: e,
        })
        .then(() => {
          db.collection("Leads")
            .doc(data?.id)
            .update({
              schoolRemark: e,
            })
            .then(() => {});
        });
    }
  };

  const RegistrationDate = (e) => {
    if (data?.id) {
      db.collection("Registered Schools")
        .doc(signInAs?.schoolId)
        .collection("All Visits")
        .doc(data?.id)
        .update({
          StudentRegistrationTime: e,
        })
        .then(() => {
          db.collection("Leads")
            .doc(data?.id)
            .update({
              StudentRegistrationTime: e,
            })
            .then(() => {});
        });
    }
  };

  const AdmissionDate = (e) => {
    if (data?.id) {
      db.collection("Registered Schools")
        .doc(signInAs?.schoolId)
        .collection("All Visits")
        .doc(data?.id)
        .update({
          StudentAdmissionTime: e,
        })
        .then(() => {
          db.collection("Leads")
            .doc(data?.id)
            .update({
              StudentAdmissionTime: e,
            })
            .then(() => {});
        });
    }
  };


  return (
    <>
      <Container
        className="Lead_Header_feild"
        style={
          inputChecked
            ? {
                color: data?.data?.color ? data?.data?.color : "#BE44D1",
                background: "rgba(141, 195, 230, 0.1)",
              }
            : { color: data?.data?.color ? data?.data?.color : "#BE44D1" }
        }
      >
        {console.warn("check all visit field data", data)}
        <div className="Date">{data?.data?.date}</div>

        <div className="Name">{data?.data?.studentName}</div>
        <div className="Contact contact_spx">
          {/* {data?.data?.number ? "xxxxxx" + data?.data?.number?.slice(data?.data?.number.split("").length-4,data?.data?.number.split("").length) : ""} */}
          {/* <h1>{data?.data?.appointment }</h1> */}
          {data?.data?.response !== "Don't Proceed" &&
          data?.data?.response !== "" &&
          data?.data?.response !== undefined
            ? data?.data?.number
            : data?.data?.number
            ? "xxxxxx" +
              data?.data?.number?.slice(
                data?.data?.number.split("").length - 4,
                data?.data?.number.split("").length
              )
            : ""}
        </div>
        <div className="Email contact_spx">
          {/* {data?.data?.email ? "xxxxxx" + data?.data?.email?.slice(data?.data?.email.split("").length-10,data?.data?.email.split("").length) : ""} */}
          {data?.data?.response !== "Don't Proceed" &&
          data?.data?.response !== "" &&
          data?.data?.response !== undefined
            ? data?.data?.email
            : data?.data?.email
            ? "xxxxxx" +
              data?.data?.email?.slice(
                data?.data?.email.split("").length - 10,
                data?.data?.email.split("").length
              )
            : ""}
        </div>

        <div className="Name">{data?.data?.sex ? data?.data?.sex : "N/A"}</div>
        <div className="Name">
          {data?.data?.standard ? data?.data?.standard : "N/A"}
        </div>

        <div className="Board" >
          {data?.data?.response ? (
            <>{data?.data?.response}</>
          ) : (
            <Dropdown
              options={arrClaimProceed}
              // disabled={active ? false : true}
       
              onChange={(e) => {
                claimProceed(e, "response");
              }}
              value={
                data?.data?.response ? data?.data?.response.slice(0, 9) : "--"
              }
              
              placeholder="Select an option"
              controlClassName="dropDown"
            />
          )}
        </div>

        {/* <div className="Name">
          {data?.data?.appointment
            ? data?.data?.appointment.length > 14
              ? data?.data?.appointment.slice(0, 45)
              : data?.data?.appointment
            : "Not Schedule"}
        </div> */}

        <div className="Name maindatory">
          {/* <h4 className="star">*</h4> */}

          <textarea
            className="remarkcss"
            name=""
            id=""
            cols="3"
            rows="2"
            placeholder="Add Remark"
            // disabled={active ? false : true}
            defaultValue={
              data?.data?.schoolRemark ? data?.data?.schoolRemark : ""
            }
            onChange={(e) => {
              setRemark(e.target.value);

              remarkInputTimer = setTimeout(() => {
                setRemark(e.target.value);
                schoolRemark(e.target.value);
              }, 3000);
            }}
          ></textarea>
        </div>

        {/* <div className="Board">
          {data?.data?.Approved ? (
            <>{data?.data?.Approved}</>
          ) : (
            <Dropdown
              options={arrApproved}
              onChange={(e) => {
                Approved(e, "Approved");
              }}
              value={
                data?.data?.Approved ? data?.data?.Approved.slice(0, 9) : "--"
              }
              placeholder="Select an option"
              controlClassName="dropDown"
            />
          )}
        </div> */}

        <div className="xyz">
          {/* {data?.data?.StudentRegistrationTime} */}

          {data?.data?.StudentRegistrationTime ? (
            data?.data?.StudentRegistrationTime
          ) : (
            <input
              className="calender"
              type={"date"}
              // onChange={(e) => {
              //   setRegistration(e.target.value);
              // }}
              defaultValue={null}
              onChange={(e) => {
                setRegistration(e.target.value);
                registrationTimer = setTimeout(() => {
                  setRegistration(e.target.value);
                  RegistrationDate(e.target.value);
                }, 5000);
              }}
            />
          )}
        </div>
        <div className="xyz">
          {data?.data?.StudentAdmissionTime ? (
            data?.data?.StudentAdmissionTime
          ) : (
            <input className="calender" type={"date"}
            onChange={(e) => {
              setAdmission(e.target.value);

              admissionTimer = setTimeout(() => {
                setAdmission(e.target.value);
                AdmissionDate(e.target.value);
              }, 5000);
            }}
            
            />
          )}
        </div>

        {/* <div className="statusNew">
          <Dropdown options={status} onChange={e => { }} value={data?.data?.status ? data?.data?.status : '--'} placeholder="--" controlClassName='dropDown' />
        </div> */}
        {/* <div className="morevert">
          <img onClick={() => setOpenmore(true)} style={{ padding: '0 10px' }} src="/Images/morevert.png" alt="" />
          {
            openmore &&
            <div className='PopFORdrop'>
              <span id="PopFORdropTop" onClick={() => setOpenmore(false)}>
                x
              </span>
              <span id="PopFORdropImg" onClick={deleteField}>
                <img src="/Images/del.png" alt="" />
                delete field</span>
            </div>
          }
        </div> */}
      </Container>
    </>
  );
}

export default Lead_Field;

const Container = styled.div`
  .Dropdown-control {
    padding: 8px 2px 8px 2px !important;
    transition: all 200ms ease;
  }
`;
