import React, { useState } from "react";

import "./Billing.css";
import BillingField from "./BillingField";
import BillingHead from "./BillingHead";

function Billing() {
  const data = [
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
    {
      Name: "Ecole Globale International Girls School",
      Changes: "Updated School Description",
      Status: "Pending",
      Time: "12/04/2022, 9:36 AM",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const increaseIndex = () => {
    if (currentIndex + 5 < data.length) {
      setCurrentIndex(currentIndex + 5);
    } else {
      console.log("nothing");
    }
  };

  const decreaseIndex = () => {
    if (currentIndex - 5 >= 0) {
      setCurrentIndex(currentIndex - 5);
    } else {
      console.log("nothing");
    }
  };

  const pack_txt = [
    "100 Leads Per Month",
    "Dedicated Business Development Executive",
    "Featured Listing in top 5",
    "20 school visits",
  ];

  return (
    // <div className='Billing'>
    //     <div className="Billing_name">
    //         Tons Bridge School, Dehradun
    //         <div className="school_package">
    //             GOLD
    //         </div>
    //     </div>
    //     <div className="package_name">
    //         Package Details
    //         <div className="pack_details">
    //             <div className="package_txt">
    //                 {pack_txt.map((pack) => (
    //                     <li>{pack}</li>
    //                 ))}
    //             </div>
    //             <div className="school_package school_package_type1">
    //                 GOLD
    //             </div>
    //             <div className="school_package school_package_type2">
    //                 SILVER
    //             </div>
    //             <div className="school_package school_package_type3">
    //                 BRONZE
    //             </div>
    //         </div>
    //     </div>
    //     <div className="Billing_Table">
    //         <span>Billing Summary</span>
    //         <BillingHead />
    //         {data && data.length > 0 && data.slice(currentIndex, currentIndex + 5).map((data, k) => (
    //             <BillingField data={data} k={k} c={currentIndex} />
    //         ))}
    //     </div>
    // </div>

    <div className="BillingTop">
      <div >
        {" "}

        <div className="Billing_name">
        Billing</div>
        <div className="BillingBox">
          <div className="imgDiv">
            <img className="imgSize" src="../Images/gold.png" alt="Gold" />
          </div>
          <div className="imgDiv">
            <img className="imgSize" src="../Images/silver.png" alt="silver" />
          </div>
          <div className="imgDiv">
            <img className="imgSize" src="../Images/bronze.png" alt="Bronze" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Billing;
