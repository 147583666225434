import React, { useState } from 'react';
import SchedulerCalendar from 'scheduler-calendar';
import 'scheduler-calendar/dist/index.css';
import styled from 'styled-components';
import db from '../../firebase';
import { useStateValue } from '../../StateProvider';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function Slots() {
    let navigate = useNavigate();

    const [availabilities, setavailabilities] = useState([]);
    const [{ signInAs, user }, dispatch] = useStateValue();

    const updateSlots = (e) => {
        if (signInAs?.schoolId) {
            db.collection('Registered Schools').doc(signInAs?.schoolId).update({
                availabilities: e,
            })
        } else {
            alert("Something went wrong!!")
        }
    }

    useEffect(() => {
        if (signInAs?.schoolId) {
            db.collection('Registered Schools').doc(signInAs?.schoolId).onSnapshot((snapshot) =>
            (
                setavailabilities(snapshot?.data()?.availabilities)
            )
            )
        } else {
            alert("Something went wrong!!")
        }
    }, [])

    return (
        <Container>
            {/* <div className='header_for_manages'>
                <span>
                    <KeyboardBackspaceRoundedIcon onClick={() => {
                        navigate('/')
                    }} />
                </span>
                <h3>
                    Visits Slots
                </h3>
                <div>
                </div>
            </div>
            <div className='scheduleSlots'>
                <SchedulerCalendar
                    availabilities={availabilities ? availabilities : []}
                    availabilityType={Infinity}
                    duration={10}
                    onIntervalChange={(e) => {
                        updateSlots(e)
                    }}
                />
            </div> */}

            <h1>This Feature Not Available</h1>
        </Container>
    )
}

export default Slots;

const Container = styled.div`
.header_for_manages{
    display: flex;
    justify-content:space-between;
    width: calc(100%) ;
    padding: 0 20px ;
    align-items:center ;
    background:white;
}

._EW260 {
    margin-top: 12px ;
}

._1DAQj {
    width: 100%;
    height: 100%;
    border: 2.5px solid #247BB2;
    padding: 6px;
}
._GlluR {
    background-size: cover;
    background-color: white;
    background-repeat: no-repeat;
    color: #8C94A1;
}
/* width:100% ; */
    /* .scheduleSlots{
        margin:12px ;
    } */
    /* align-items: ; */
`