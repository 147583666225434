import React from 'react'
import { Chart } from 'react-charts'
 
 export default function SchoolChart({currentVisits , leads , currentleads}) {
    const data = React.useMemo(
      () => [
        {
          label: 'Series 1',
          data: [{ x: 0, y: 0 }, { x: 1, y: currentVisits ? currentVisits.length : 0 }]
        },
        {
          label: 'Series 2',
          data: [{ x: 0, y: 0 }, { x: 1, y: leads ? leads.length : 0 }]
        },
        {
          label: 'Series 3',
          data: [{ x: 0, y: 0 }, { x: 1, y: currentleads ? currentleads.length : 0 }]
        },
      ],
      []
    )
   
    const axes = React.useMemo(
      () => [
        { primary: true, type: 'linear', position: 'bottom' },
        { type: 'linear', position: 'left' }
      ],
      []
    )
   
    return (
      <div
        style={{
          width: '1150px',
          height: '200px',
        }}
      >
        <Chart data={data} axes={axes} />
      </div>
    )
  }