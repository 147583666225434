import React from 'react';
import './SchoolReportsHeader.css'

function SchoolReportsHeader() {
  return (
    // <div>SchoolReportsHeader</div>
    <div className='SchoolReportsHeader'>
        <div className="SchoolReportsHeader_no" >
       
      Date
        </div>
        <div className="SchoolReportsHeader_name">
        Name
        </div>
        <div className="SchoolReportsHeader_Standard">
        Contact
        </div>
        <div className="SchoolReportsHeader_sex">
        Email
        </div>
        <div className="SchoolReportsHeader_cat">
        Status
        </div>
        <div className="SchoolReportsHeader_Visit">
        Appointment
        </div>
        <div className="SchoolReportsHeader_admission">
        Approved
        </div>
        <div className="SchoolReportsHeader_number">
        Registration
        </div>
        <div className="SchoolReportsHeader_details">
        Admission
        </div>
    </div>
  )
}

export default SchoolReportsHeader