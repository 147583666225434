import React from 'react'

function SchoolReportsField({ data,k,c }) {
 

  return (


    <div className='SchoolReportsField'>
        <div className="SchoolReportsHeader_no" >
        {data?.data?.date}
        </div>
        <div className="SchoolReportsHeader_name">
        {data?.data?.studentName ? data?.data?.studentName : "N/A"}
    
        </div>
        <div className="SchoolReportsHeader_Standard">
        {data?.data?.number ? "xxxxxx" + data?.data?.number?.slice(data?.data?.number.split("").length-4,data?.data?.number.split("").length) : "N/A"}

        {/* {data?.data?.number ? data?.data?.number : "N/A"} */}
        </div>
        <div className="SchoolReportsHeader_sex">
       {data?.data?.email ? "xxxxxx" + data?.data?.email?.slice(data?.data?.email.split("").length-10,data?.data?.email.split("").length) : "N/A"}

        {/* {data?.data?.email ?  data?.data?.email : "N/A"} */}
        </div>
        <div className="SchoolReportsHeader_cat">
        {data?.data?.response ? data?.data?.response : "Pending"}
        </div>
        <div className="SchoolReportsHeader_Visit">
        {data?.data?.appointment ? data?.data?.appointment :  "Not Schedule"
}
        </div>
        <div className="SchoolReportsHeader_admission">
        {data?.data?.Approved ? data?.data?.Approved : "Pending"}
        </div>
        <div className="SchoolReportsHeader_number">
        {data?.data?.StudentRegistrationTime ? data?.data?.StudentRegistrationTime : "Not Registered"}

        </div>
        <div className="SchoolReportsHeader_details">
        {data?.data?.StudentAdmissionTime ? data?.data?.StudentAdmissionTime : "N/A"}

        </div>
    </div>
  )
}

export default SchoolReportsField