import React, { useState, useEffect } from 'react';
import './Login.css';
import { useNavigate } from "react-router-dom";
import { auth } from '../../firebase';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import CircularProgress from '@mui/material/CircularProgress';

function Login() {
  const [{ signInAs, user }, dispatch] = useStateValue();


  let navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false)

  const forgotPassword = () => {
    if (email) {
      setLoading(true);
      auth.sendPasswordResetEmail(email)
        .then(() => {
          // Password reset email sent!
          alert('Password reset email sent!')
          setLoading(false);
          // ..
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setLoading(false);
          // ..
        });
    } else {
      alert("Please enter email to reset password");
      setLoading(false);
    }
  }

  const sign_in = (e) => {
    setLoading(true);
    e.preventDefault();
    auth.signInWithEmailAndPassword(email, password)
      .then((auth) => {
        dispatch({
          type: actionTypes.SET_USER,
          user: auth.user,
        })
        navigate('/')
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert(error.message)
      });
  };

  return (
    <>
    {
      loading && 
      <div className="loadingBackground">
        <CircularProgress/>
      </div>
    }
    <div className='Signup'>
      <div className="signup_body">
        <div className="signup_bodyDiv1">
          <div className="signupImagesDiv">
            <img src="/Images/key.png" alt="key image" />
            <img src="/Images/users.jpg" alt="book image" />
          </div>
          <input type="text" placeholder='Email' onChange={e => setEmail(e.target.value)} className='signUp_input' />
          <input type="text" placeholder='Password' onChange={e => setPassword(e.target.value)} className='signUp_input' />
          <div className="checkdiv">
            <input type="checkbox" />
            <span>Remember me</span>
          </div>
          <button onClick={sign_in}>Login</button>
          <div className="forgot" onClick={forgotPassword}>
            forgot password
          </div>
        </div>
        <div className="signup_bodyDiv2">
          <img className='signup_bodyDiv2_img1' src="/Images/background.png" alt="background image" />
          <div className="signup_bodyDiv2div">
            <img className='signup_bodyDiv2_img2' src="/Images/Eduminattilogo.png" alt="" />
            <div className='signup2txt1'>Welcome!</div>
            <div className='signup2txt2'>
              <span onClick={() => navigate('/signup')}>Sign up </span>
              {' '} if you are a first time user</div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Login;